import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "heading": "Great coffee with a conscience",
  "path": "/products",
  "templateKey": "product-page",
  "title": "Josh's Coffee",
  "image": "/img/jumbotron.jpg",
  "description": "Kaldi is the ultimate spot for coffee lovers who want to learn about their java’s origin and support the farmers that grew it. We take coffee production, roasting and brewing seriously and we’re glad to pass that knowledge to anyone.",
  "intro": {
    "blurbs": [{
      "image": "/img/coffee.png",
      "text": "We sell green and roasted coffee beans that are sourced directly from independent farmers and farm cooperatives. We’re proud to offer a variety of coffee beans grown with great care for the environment and local communities. Check our post or contact us directly for current availability.\n"
    }, {
      "image": "/img/coffee-gear.png",
      "text": "We offer a small, but carefully curated selection of brewing gear and tools for every taste and experience level. No matter if you roast your own beans or just bought your first french press, you’ll find a gadget to fall in love with in our shop.\n"
    }, {
      "image": "/img/tutorials.png",
      "text": "Love a great cup of coffee, but never knew how to make one? Bought a fancy new Chemex but have no clue how to use it? Don't worry, we’re here to help. You can schedule a custom 1-on-1 consultation with our baristas to learn anything you want to know about coffee roasting and brewing. Email us or call the store for details.\n"
    }, {
      "image": "/img/meeting-space.png",
      "text": "We believe that good coffee has the power to bring people together. That’s why we decided to turn a corner of our shop into a cozy meeting space where you can hang out with fellow coffee lovers and learn about coffee making techniques. All of the artwork on display there is for sale. The full price you pay goes to the artist.\n"
    }],
    "heading": "What we offer",
    "description": "Kaldi is the ultimate spot for coffee lovers who want to learn about their java’s origin and support the farmers that grew it. We take coffee production, roasting and brewing seriously and we’re glad to pass that knowledge to anyone. This is an edit via identity...\n"
  },
  "main": {
    "heading": "Great coffee with no compromises",
    "description": "We hold our coffee to the highest standards from the shrub to the cup. That’s why we’re meticulous and transparent about each step of the coffee’s journey. We personally visit each farm to make sure the conditions are optimal for the plants, farmers and the local environment.\n",
    "image1": {
      "alt": "A close-up of a paper filter filled with ground coffee",
      "image": "/img/products-grid3.jpg"
    },
    "image2": {
      "alt": "A green cup of a coffee on a wooden table",
      "image": "/img/products-grid2.jpg"
    },
    "image3": {
      "alt": "Coffee beans",
      "image": "/img/products-grid1.jpg"
    }
  },
  "testimonials": [{
    "author": "Elisabeth Kaurismäki",
    "quote": "The first time I tried Kaldi’s coffee, I couldn’t even believe that was the same thing I’ve been drinking every morning."
  }, {
    "author": "Philipp Trommler",
    "quote": "Kaldi is the place to go if you want the best quality coffee. I love their stance on empowering farmers and transparency."
  }],
  "full_image": "/img/products-full-width.jpg",
  "pricing": {
    "heading": "Monthly subscriptions",
    "description": "We make it easy to make great coffee a part of your life. Choose one of our monthly subscription plans to receive great coffee at your doorstep each month. Contact us about more details and payment info.",
    "plans": [{
      "description": "Perfect for the drinker who likes to enjoy 1-2 cups per day.",
      "items": ["3 lbs of coffee per month", "Green or roasted beans\"", "One or two varieties of beans\""],
      "plan": "Small",
      "price": "50"
    }, {
      "description": "Great for avid drinkers, java-loving couples and bigger crowds",
      "items": ["6 lbs of coffee per month", "Green or roasted beans", "Up to 4 different varieties of beans"],
      "plan": "Big",
      "price": "80"
    }, {
      "description": "Want a few tiny batches from different varieties? Try our custom plan",
      "items": ["Whatever you need", "Green or roasted beans", "Unlimited varieties"],
      "plan": "Custom",
      "price": "??"
    }]
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      